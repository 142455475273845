import React from "react";
import SEO from "../components/seo";
import Chapter2 from "src/views/Chapter2";

const IndexPage = () => {
  return (
    <>
      <SEO title="Miłość do rodziców, miłość do żony Barbary" description="Miłość Krzysztofa Kamila Baczyńskiego do rodziców i do Barbary, narzeczonej i żony. Ich miłość, dzięki poezji Baczyńskiego, przeszła do historii literatury." image={require('images/seo/2.jpg')}/>
      <Chapter2 />
    </>
  );
};

export default IndexPage;
