import React from "react"
import { AudioPlayerGroup } from "src/components/AudioPlayer"
import TitlePage from "src/components/TitlePage"
import CustomGrid, { Section, Group } from "src/components/CustomGrid"
import { GRIDS } from "src/util"
import { useArticles } from "src/hooks"
import parse from "html-react-parser"
import { BodyTextWrap, TitleContrast } from "src/atoms/typography"
import { Bookshelf } from "src/atoms"
import NextChapter from "../../components/NextChapter"
import { AnimatePresence, motion } from "framer-motion"

const Chapter2 = () => {
  const articles = useArticles({ articleCategory: 9047 })
  const { list } = articles

  if (list.length)
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3.5 }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <TitlePage
              title={list[0]?.Title}
              subtitle={list[0].Lead}
              description={parse(list[0].Content)}
            />
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[1].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[1].Content)}
                  </BodyTextWrap>
                  <AudioPlayerGroup list={list.slice(8, 10)} />
                </div>
                <CustomGrid
                  photos={[
                    list[2],
                    list[3],
                    list[4],
                    list[5],
                    list[6],
                    list[7],
                  ]}
                  grid={GRIDS.p9}
                  heightRatio={0.97}
                />
              </CustomGrid>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[10].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[10].Content)}
                  </BodyTextWrap>
                </div>
                <CustomGrid
                  photos={[list[11]]}
                  grid={GRIDS.p0}
                  heightRatio={0.64}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid>
                  <AudioPlayerGroup list={list.slice(12, 14)} />
                </CustomGrid>
              </Group>
              <CustomGrid
                photos={[
                  list[14],
                  list[15],
                  list[16],
                  list[17],
                  list[18],
                  list[19],
                ]}
                grid={GRIDS.p4}
                heightRatio={0.52}
                photoStyles={[
                  {},
                  {},
                  { backgroundPosition: "top" },
                  {},
                  { backgroundPosition: "top" },
                ]}
              />
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[20].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[20].Content)}
                  </BodyTextWrap>
                  <AudioPlayerGroup list={list.slice(23, 25)} />
                </div>
                <CustomGrid
                  photos={[list[21], list[22], list[25], list[26], list[27]]}
                  grid={GRIDS.p6}
                  heightRatio={1.41}
                />
              </CustomGrid>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[28].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[28].Content)}
                  </BodyTextWrap>
                  <AudioPlayerGroup list={[list[31], list[34]]} />
                </div>
                <CustomGrid
                  photos={[
                    list[29],
                    list[30],
                    list[32],
                    list[33],
                    list[35],
                    list[36],
                  ]}
                  grid={GRIDS.p10}
                  heightRatio={1.41}
                />
                <AudioPlayerGroup list={[list[37], list[38]]} />
              </CustomGrid>
            </Section>
            <Section>
              <Bookshelf />
              <CustomGrid>
                <div>
                  <AudioPlayerGroup
                    list={[list[40], list[41], list[44], list[46]]}
                  />
                </div>
                <CustomGrid
                  photos={[list[42], list[43], list[45], list[47]]}
                  grid={GRIDS.p11}
                  heightRatio={1.05}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid
                  photos={[list[48], list[50], list[49], list[51], list[52]]}
                  grid={GRIDS.p5}
                  heightRatio={0.52}
                />
              </Group>
            </Section>
          </div>
          <NextChapter title="Przyjaźń" href="/przyjazn" />
        </motion.div>
      </AnimatePresence>
    )
  return null
}

export default Chapter2
